import { TypeT } from "./../app/types/types";
import {
  REACT_APP_MAX_FILTER,
  REACT_APP_MIN_FILTER,
  TypeQuerySearch,
} from "../app/index";
import { useState } from "react";
import { useQuery } from "react-query";
import { getProducts } from "../features/products/getProducts";
import { useFilterParams } from "./useFilterParams";

export const useFetchProducts = (
  collection: string,
  excludeCategories: string,
  token: TypeT | undefined
) => {
  const [currentPage, setCurrentPage] = useState(1);

  const [querySearch, setquerySearch] = useState<TypeQuerySearch>({
    categories: [],
    keyword: "",
    rangeValue: [REACT_APP_MIN_FILTER, REACT_APP_MAX_FILTER],
  });

  useFilterParams(setquerySearch, querySearch);

  const result: any = useQuery(
    ["products", { collection, currentPage, querySearch, token }],
    async () => {
      return (
        token &&
        (await getProducts(
          currentPage,
          collection,
          excludeCategories,
          querySearch,
          token
        ))
      );
    },
    { refetchOnWindowFocus: false }
  );
  return {
    result,
    currentPage,
    setCurrentPage,
    querySearch,
    setquerySearch,
  };
};

export const PRODUCT_DIGITAL = 'Digitale';
