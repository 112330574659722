const colors = {
  wishlist: 
  {
    50: '#fffedd',
    100: '#fcf6b3',
    200: '#f8ec85',
    300: '#d9d711',
    400: '#d9d711',
    500: '#a0a909',
    600: '#B9CE08',
    700: '#6b7904',
    800: '#394800',
    900: '#141a00',
  },
  wai: {
    secondary: "#F3EAEA",
    primary: "#cf2e2e",
    cta: "rgba(112, 195, 111, 0.9)",
    ctaText: '#000'
  }
};

export default colors;


