import { Badge, Box, Center, Heading, Image } from "@chakra-ui/react";
import { ProductProps } from "../../app/index";
import { decodeHtmlCharCodes } from "../../utils/stringCodeFormatter";
import ModalDetailProduct from "./modalDetailProduct";



const ProductElement = (product: ProductProps) => {
  
  return (
    <Box
      paddingRight="1em"
      paddingLeft="1em"
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      borderStyle="solid"
      color={process.env.REACT_APP_PARTNER_PRIMARY}
    >
      <Heading
        mt="1"
        textAlign={"center"}
        fontWeight="semibold"
        fontSize="1.3em"
        as="h4"
        lineHeight="normal"
        color={process.env.REACT_APP_PARTNER_PRIMARY}
        textTransform="none"
        minH={"3em"}
      >
        {decodeHtmlCharCodes(product.productname)}
      </Heading>

      <Box position="relative">
        <Badge
          borderRadius="10px"
          bgColor={process.env.REACT_APP_PARTNER_PRIMARY}
          textAlign={"center"}
          
          textColor={"#fff"}
          position={"absolute"}
          zIndex={100}
          right={0}
          fontSize={"md"}
        >
          {product.fascia} €
        </Badge>

        <Center>
          <Image
            boxSize={"-moz-fit-content"}
            src={`${product.baseURL}${product.imagename}`}
            alt={product.descrizione}
          />
        </Center>
      </Box>
      <Box p="2">
        <Center>
          <Badge
            m="0.5em"
            borderRadius="base"
            variant="subtle"
            backgroundColor="#fff"
            border="1px solid"
            padding="0.3em"
            borderColor={process.env.REACT_APP_PARTNER_PRIMARY}
            fontSize="medium"
            fontWeight="bold"
            color={process.env.REACT_APP_PARTNER_PRIMARY}
          >
            {product.brand}
          </Badge>
          <Box
            m="0.5em"
            borderRadius="base"
            color={process.env.REACT_APP_PARTNER_PRIMARY}
            fontWeight="normal"
            backgroundColor="#fff"
          >
            {product.product_categories}
          </Box>
        </Center>
        <Box color="gray.500" noOfLines={2} marginTop="1em" marginBottom="1em">
          {decodeHtmlCharCodes(product.descrizione)}

        </Box>
        <ModalDetailProduct {...product} baseURL={product.baseURL} />
      </Box>
    </Box>
  );
};

export default ProductElement;
