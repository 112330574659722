import { Badge, Center, VStack } from "@chakra-ui/react";

type BadgeCreditProps = {
  credit100: number;
  credit51c3: number;
  buy?: {
    buyCredit51c3: boolean;
    buyCredit100: boolean;
  };
};


export const BadgeCredit = ({
  credit100,
  credit51c3,
  buy,
}: BadgeCreditProps) => {
  return (
    <VStack>
      <Center>
        <Badge
          borderRadius="10px"
          bgColor={process.env.REACT_APP_PARTNER_PRIMARY}
          textColor={"#fff"}
          margin="0.5em"
        >
          Credito Buoni {credit51c3} €
        </Badge>
        <Badge
          borderRadius="10px"
          bgColor={process.env.REACT_APP_PARTNER_PRIMARY}
          textColor={"#fff"}
          margin="0.5em"
        >
          Credito Residuo {credit100} €
        </Badge>
      </Center>
      <Center>
        {buy?.buyCredit51c3 && `Puoi usare il tuo credito buoni (art51c3)`}
        {buy?.buyCredit100 &&
          `Puoi usare il tuo credito residuo`}
      </Center>
    </VStack>
  );
};
