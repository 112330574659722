import { TypeRangeCollection } from "./../types/types";
/**
 * CATEGORIE
 */
export const categoryList: TypeCategory[] = [
  // { key: "595316", value: "Buoni" },
  { key: "24773", value: "Casa e Design" },
  { key: "24772", value: "Sport e Hobby" },
  { key: "24776", value: "Esperienze e Servizi" },
  { key: "24775", value: "Hi-Tec" },
  { key: "24774", value: "Gourmet" },
  { key: "24771", value: "Per Lui" },
  { key: "24711", value: "Per Lei" },
  { key: "76809", value: "Bambini" },
  { key: "598752", value: "Exclusive" },
];

export type TypeCategory = {
  key: string;
  value: string;
};

export type TypeQuerySearch = {
  categories: TypeCategory[];
  keyword: string;
  rangeValue: number[];
};

// FIXME: spostare in configurazione o comunque esterna al deploy
export const rangeCollection: TypeRangeCollection[] = [


  { idCollection: "fd2dcbffaf3d4f21ac46eaedd85013c2", amount: 1 },
  { idCollection: "f91b3aace98f569e9d08db694a913f5c", amount: 5 },
  { idCollection: "98fed5f6722bfc73254eedbe9b01fd4a", amount: 10 },
  { idCollection: "35d6f2a55326d5bb706d6b023793be54", amount: 15 },
  { idCollection: "31dd25e04e6a5c391baef1ab28c9a954", amount: 20 },
  { idCollection: "f5ef5c35112cfcaf051899dbfa07083f", amount: 25 },
  { idCollection: "7990123a7b400e363c8a227aa463970e", amount: 35 },
  { idCollection: "e9f7eaecbf105db75a832dc04e59aebc", amount: 50 },
  { idCollection: "28c7d471977d60fcbbfe99cc379134d7", amount: 75 },
  { idCollection: "fe5d705e5c97407f265f1206f3460e5a", amount: 90 },
  { idCollection: "9a8208e23c4ca38e13b730208b0c5449", amount: 100 },
  { idCollection: "cbf1be42ab4ab23c131e00cec701edc5", amount: 125 },
  { idCollection: "6b5d6d14f2565962667368ba016f4842", amount: 150 },
  { idCollection: "7bdd906d4bcd7c1c561dc46cb50d9b49", amount: 200 },
  { idCollection: "c20fe86899358a5d30bbc7739dde5ac5", amount: 250 },
  { idCollection: "e2531a7e1960b1c8e7a954fac92cde70", amount: 300 },
  { idCollection: "395c3dbf6cfb9093b5e6184555477fe9", amount: 500 },
  { idCollection: "87d6690f419533895e2209f7c5cce5d7", amount: 750 },
  { idCollection: "c51d5c52400ebe5f6208d71cf561dc33", amount: 1000 },
  { idCollection: "6f3fade9ddeee13d6daa0d203f8c27e2", amount: 2000 }
];

export const REACT_APP_MIN_FILTER = process.env.REACT_APP_MIN_FILTER
  ? parseInt(process.env.REACT_APP_MIN_FILTER)
  : 0;
export const REACT_APP_MAX_FILTER = process.env.REACT_APP_MAX_FILTER
  ? parseInt(process.env.REACT_APP_MAX_FILTER)
  : 2000;
