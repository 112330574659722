import { AtSignIcon } from "@chakra-ui/icons";
import {
    Alert,
    AlertDescription,
    AlertTitle,
    Box, Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Link
} from "@chakra-ui/react";
import * as React from "react";
import { HelpFAQ } from "./helpFAQ";
export interface HelpBannerProps {
  isOpen: boolean;
  onClose: () => void;
  btnRef: React.RefObject<HTMLButtonElement>;
}
export const HelpBanner = ({ isOpen, onClose, btnRef }: HelpBannerProps) => {
  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton colorScheme="red" />
          <DrawerHeader color={process.env.REACT_APP_PARTNER_PRIMARY}>Le domande più frequenti</DrawerHeader>

          <DrawerBody>
            <HelpFAQ />
          </DrawerBody>

          <DrawerFooter>
            <Alert status="info" bgColor={process.env.REACT_APP_PARTNER_SECONDARY}>
              <AtSignIcon color={process.env.REACT_APP_PARTNER_PRIMARY} m="0.4em" fontSize="2xl" />
              <Box flex="1">
                <AlertTitle color={process.env.REACT_APP_PARTNER_PRIMARY}>
                  Non hai trovato la soluzione al tuo problema?
                </AlertTitle>
                <AlertDescription color={process.env.REACT_APP_PARTNER_PRIMARY} display="block">
                  Chiamaci allo{" "}
                  <Link fontWeight="bold" href="tel:0236638510">
                    02 3663 8510
                  </Link>{" "}
                  o scrivici a{" "}
                  <Link fontWeight="bold" href="mailto:ordini@wishlist.it">
                    ordini@wishlist.it
                  </Link>
                  .
                </AlertDescription>
              </Box>
            </Alert>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
