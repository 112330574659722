import {
  Badge,
  Box,
  Button,
  Center,
  Divider,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProductProps } from "../../app/index";
import { TBuy } from "../../app/types/checkoutType";
import { useValidateSession } from "../../hooks";
import { useCheckout } from "../../hooks/useCheckout";
import { getBuy } from "../../utils/checkBuyUtil";
import { decodeHtmlCharCodes } from "../../utils/stringCodeFormatter";
import { BadgeCredit } from "../badgeCredit";




const ModalDetailProduct = (product: ProductProps) => {
  const { user } = useValidateSession(null);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setCheckoutProduct } = useCheckout();
  const [buy, setBuy] = useState<TBuy | null>(null);

  useEffect(() => {
    if (user && product) {
      setBuy(getBuy(user, product));
    }
  }, [product, user]);

  const setProduct = async (prodotto: ProductProps) => {
    await setCheckoutProduct(prodotto);
    navigate("/checkout");
  };

  return (
    <>
      <Center mt={4} mb={4}>
        <Button
          onClick={onOpen}
          bgColor={process.env.REACT_APP_PARTNER_CTA}
          color={process.env.REACT_APP_PARTNER_CTA_TEXT}
          _hover={{ bg: process.env.REACT_APP_PARTNER_CTA }}
          _active={{
            bg: process.env.REACT_APP_PARTNER_CTA,
            transform: "scale(0.98)",
            borderColor: process.env.REACT_APP_PARTNER_CTA,
          }}
        >
          Dettagli
        </Button>
      </Center>

      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        scrollBehavior="inside"
        size="full"
        
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading textAlign={"center"} color={process.env.REACT_APP_PARTNER_PRIMARY} fontSize={["xl", "2xl", "3xl"]}>
              {decodeHtmlCharCodes(product.productname)}
            </Heading>
          </ModalHeader>
          <Divider orientation="horizontal" borderColor={process.env.REACT_APP_PARTNER_PRIMARY} />
          <ModalCloseButton />
          <ModalBody padding={"2em"} >
            <SimpleGrid columns={[1, 1, 2]} spacing={5} w={"100%"}>
              <Center>
                <Image
                  boxSize={["md", "lg", "xl"]}
                  fit={"contain"}
                  src={`${product.baseURL}${product.imagename}`}
                  alt={product.descrizione_lunga}
                />
              </Center>
              <Box
                padding={"1em"}
                boxShadow="-1px 0px 1px 0px rgba(0, 0, 0, 0.1)"
                
              >
                <Box >
                  <Badge
                    m="0.5em"
                    borderRadius="base"
                    variant="subtle"
                    backgroundColor="#fff"
                    border="1px solid"
                    padding="0.3em"
                    borderColor={process.env.REACT_APP_PARTNER_PRIMARY}
                   
                    fontWeight="bold"
                    color={process.env.REACT_APP_PARTNER_PRIMARY}
                    fontSize={["sm", "md"]}
                  >
                    {product.brand}
                  </Badge>
                  <Badge
                    m="0.5em"
                    borderRadius="base"
                    variant="subtle"
                    color={process.env.REACT_APP_PARTNER_PRIMARY}
                    fontWeight="normal"
                    backgroundColor="#fff"
                    fontSize={["sm", "md"]}
                  >
                    <Text>{product.product_categories}</Text>
                  </Badge>
                </Box>
                <Text
                  p={"1em"}
                  fontSize={"md"}
                  fontWeight="bold"
                  textColor={process.env.REACT_APP_PARTNER_PRIMARY}
                  textAlign="left"
                >
                  Prezzo: {product.fascia},00 €
                </Text>
                <Text mt={"2em"} mb="2em" fontSize={["sm", "md"]} dangerouslySetInnerHTML={{__html: product.descrizione_lunga}} />
  
                <Center mb={"1em"}>
                  <Button
                    bgColor={process.env.REACT_APP_PARTNER_CTA}
                    color={process.env.REACT_APP_PARTNER_CTA_TEXT}
                    _hover={{ bg: process.env.REACT_APP_PARTNER_CTA }}
                    _active={{
                      bg: process.env.REACT_APP_PARTNER_CTA,
                      transform: "scale(0.98)",
                      borderColor: process.env.REACT_APP_PARTNER_CTA,
                    }}
                    isDisabled={buy && (buy.buy.buyCredit51c3 || buy.buy.buyCredit100) ? false : true}
                    onClick={() => setProduct(product)}
                    fontSize={"md"}
                  >
                    Richiedi
                  </Button>
                  <Button
                    ml={5}
                    onClick={onClose}
                    variant="outline"
                    color={process.env.REACT_APP_PARTNER_CTA_TEXT}
                    borderColor={process.env.REACT_APP_PARTNER_CTA}
                    fontSize={"md"}
                  >
                    Chiudi
                  </Button>
                </Center>
                <Center>
                  {buy &&
                    <BadgeCredit credit100={buy.credit100} credit51c3={buy.credit51c3} buy={{buyCredit100: buy.buy.buyCredit100, buyCredit51c3: buy.buy.buyCredit51c3}} />
                  }
                </Center>
              
              </Box>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalDetailProduct;

