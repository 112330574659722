import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import * as React from "react";
export interface HelpFAQProps {}
export const HelpFAQ = (props: HelpFAQProps) => {
  return (
    <Accordion color={process.env.REACT_APP_PARTNER_PRIMARY}>
       <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" fontWeight="bold">
              Quali prodotti e buoni avete nel catalogo?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
        Il catalogo contiene un enorme scelta di buoni e prodotti. Per trovare facilmente quello che cerchi usa il bottone "Filtri". 
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box fontWeight="bold" flex="1" textAlign="left">
              La spedizione è sempre gratuita?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          Si, se richiedi uno dei nostri prodotti a catalogo lo spediamo
          gratuitamente. Nessun costo extra è richiesto per la spedizione.
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box fontWeight="bold" flex="1" textAlign="left">
              I crediti disponibili non ti sembrano aggiornati?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          Se i tuoi crediti non ti sembrano correttamente aggiornati puoi
          chiudere la finestra e rientrare attraverso il portale di {process.env.REACT_APP_PROVIDER} per l'ultimo aggiornamento disponibile.
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" fontWeight="bold">
            In quali negozi posso spendere il mio buono?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
        Cliccando sul tasto "Dettagli" sopra a ciascun buono, troverai il link all'elenco dei negozi dove viene accettato.
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" fontWeight="bold">
            Devo spendere tutto l'importo del mio buono in un'unica soluzione?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
        Salvo pochi casi, dove è chiaramente specificato nei "Dettagli", i buoni sono sempre spendibili in più riprese fino all'esaurimento del credito.
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" fontWeight="bold">
            Quanto tempo ho per spendere il mio buono?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
        Il periodo di validità del voucher è sempre indicato sul buono stesso.
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" fontWeight="bold">
            Cosa significa credito Buoni (art.51c3) e credito welfare?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
        Il credito Buoni (art.51c3) può essere utilizzato per richiedere qualsiasi prodotto nel nostro catalogo. Il credito Welfare può essere utilizzato solo per alcuni prodotti, la lista delle possibilità a tua disposizione calerà, quindi, successivamente al progressivo utilizzo del credito Buoni (art.51c3).
        </AccordionPanel>
      </AccordionItem>
     
    </Accordion>
  );
};
