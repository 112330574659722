import { ErrorType } from "../../app/index";
import { CodeAlert } from "./codeAlert";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Center } from "@chakra-ui/react";

interface ErrorViewProps {
  error: ErrorType | undefined;
}

export default function ErrorView({ error }: ErrorViewProps) {
  const [isAlertOpen] = useState(true);
  const navigate = useNavigate();

  const onCloseAlert = () => {
    navigate(-2); 
  };

  return (
    <Center h={"4xl"}>
      <CodeAlert isOpen={isAlertOpen} error={error} onClose={onCloseAlert} />
    </Center>
  );
}
