import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { Box, Container, HStack } from "@chakra-ui/react";
import * as React from "react";

export interface PaginatorProps {
  totalPages: number;
  currentPage: number;
  setCurrentPage: any;
}

const gotogrid = "#grid-result";

export const Paginator = (props: PaginatorProps) => {
  const next = () => {
    props.setCurrentPage(props.currentPage + 1);
    window.location.href = gotogrid;
  };
  const previous = () => {
    if (props.currentPage > 1) props.setCurrentPage(props.currentPage - 1);
    window.location.href = gotogrid;
  };

  return (
    <Container marginTop="5px" color={process.env.REACT_APP_PARTNER_PRIMARY}
     w="100%" alignSelf={"auto"} float={"right"} >
      <HStack  float={"right"} >
        {props.totalPages > 1 && props.currentPage > 1 && (
          <ArrowLeftIcon onClick={previous} />
        )}

        <Box paddingRight="1em" paddingLeft={"1em"}
          fontSize="1.1em"
        >
          Pagina {props.currentPage} di {props.totalPages}
        </Box>
        {props.currentPage < props.totalPages && (
          <ArrowRightIcon onClick={next} />
        )}
      </HStack>
    </Container>
  );
};
