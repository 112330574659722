import { useState } from "react";
import { callApiSendOrder } from "../api/sendOrder";
import { ProductProps } from "../app/index";
import { CheckoutType } from "../app/types/checkoutType";
import { updateProductCheckout } from "../features/checkout/checkoutSlice";
import { updateCheckoutCredit } from "../utils/checkBuyUtil";
import { useAppDispatch, useAppSelector } from "./reduxHooks";
import { useValidateSession } from "./useValidateSession";

export const useCheckout = () => {
  const checkout: CheckoutType = useAppSelector(
    (state) => state.updateCheckout
  );
  
  const { user, updateSession } = useValidateSession(null);
  const dispatch = useAppDispatch();
  const [processing, setProcessing] = useState(0);

  const setCheckoutProduct = (prodotto: ProductProps) => {
    const newCheckout: CheckoutType = { ...checkout };
    newCheckout.prodotto = prodotto;
    dispatch(updateProductCheckout(newCheckout));
  };

  const createOrder = async (checkout: CheckoutType) => {
    const receipt = await callApiSendOrder(checkout);
    return receipt;
  };

  const confirmCheckout = async (values: any) => {
    try {
      // Processing order
      setProcessing(1);

      // Setup order
      const newCheckout: CheckoutType = { ...checkout, ...values };
      newCheckout.provincia = newCheckout.provincia.toUpperCase();
      newCheckout.citta = newCheckout.citta.toUpperCase();
      newCheckout.d = user?.d ? user?.d : "";
      newCheckout.idutente_finale = user?.userId ? user?.userId : "";
      newCheckout.email = user?.email ? user?.email : "";
      newCheckout.nome = user?.firstName ? user?.firstName : "";
      newCheckout.cognome = user?.lastName ? user?.lastName : "";

      if (
        newCheckout.email === "" ||
        newCheckout.nome === "" ||
        newCheckout.cognome === ""
      )
        throw new Error("Nome, cognome, email sono informazioni obbligatorie");

      // Send order to server
      const receipt = await createOrder(newCheckout);
      newCheckout.receipt = receipt;

      await dispatch(updateProductCheckout({ ...checkout, ...newCheckout }));

      // Update session user credit
      if (user) {
        updateSession(updateCheckoutCredit(user, newCheckout));
      }

      // End processing order
      setProcessing(2);
    } catch (error: any) {
      await dispatch(
        updateProductCheckout({
          ...checkout,
          error: { status: "error", message: error.message === "ERR_BAD_REQUEST Request failed with status code 420" ? "Impossibile inviare l'ordine, controlla il tuo credito sul portale Wellwork" : "Errore di connessione, rieffettuare l'accesso"},
        })
      );
      setProcessing(2);
    }
  };
  return {
    checkout,
    user,
    setCheckoutProduct,
    confirmCheckout,
    processing,
    createOrder,
  };
};
