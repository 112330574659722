import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Button,
  Menu,
  MenuButton, MenuGroup, MenuList
} from "@chakra-ui/react";
import React from "react";
import { WBToken } from "../../app/index";

export const User = (user: WBToken) => {
  return (
    <Menu>
      <MenuButton as={Button} leftIcon={<ArrowForwardIcon />}
      color={process.env.REACT_APP_PARTNER_PRIMARY}  bgColor="#fff" fontSize={["sm", "md"]}
      size={"sm"}
      >
        {`Il tuo saldo`}
      </MenuButton>
      <MenuList zIndex="201">
        <MenuGroup title={`Credito Residuo: `} fontWeight={"normal"}></MenuGroup>
        <MenuGroup title={`${user.credit100}€`}></MenuGroup>
        <MenuGroup title={`di cui Buoni (art51c3): `}  fontWeight={"normal"}></MenuGroup>
        <MenuGroup title={`${user.credit51c3}€`}></MenuGroup>
      </MenuList>
    </Menu>
  );
};
